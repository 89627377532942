<template>
  <div
    :class="getBadgeClass()"
  >
    <img
      :class="getImageClass()"
      :src="getImageSrc()"
    >
  </div>
</template>
<script>
export default {
  props: {
    imageStyle: {
      type: String,
      required: true
    },
    alignment: {
      type: String,
      required: true
    },
    fileUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    getBadgeClass () {
      return `badge badge-alignment-${this.alignment}`;
    },
    getImageClass () {
      return this.imageStyle;
    },
    getImageSrc () {
      return '../../assets/' + this.fileUrl;
    }
  }
};
</script>
  <style>
  .badge {
    display: flex;
    position: relative;
  }

  .badge-alignment-top,
  .badge-alignment-top-left,
  .badge-alignment-top-right {
    padding-top: 10px;
    grid-row: 1;
    align-items: start;
  }

  .badge-alignment-left,
  .badge-alignment-center,
  .badge-alignment-right {
    padding-top: 5px;
    padding-bottom: 5px;
    grid-row: 2;
    align-items: center;
  }

  .badge-alignment-bottom,
  .badge-alignment-bottom-left,
  .badge-alignment-bottom-right {
    padding-bottom: 10px;
    grid-row: 3;
    align-items: end;
  }

  .badge-alignment-top-left,
  .badge-alignment-left,
  .badge-alignment-bottom-left {
    grid-column: 1;
    padding-left: 10px;
    justify-content: left;
  }

  .badge-alignment-top,
  .badge-alignment-center,
  .badge-alignment-bottom {
    grid-column: 2;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
  }

  .badge-alignment-top-right,
  .badge-alignment-right,
  .badge-alignment-bottom-right {
    grid-column: 3;
    padding-right: 10px;
    justify-content: right;
  }

  .image-default-product {
    width: 72px;
  }
  .image-default-product-list {
    width: 48px;
  }

  @media screen and (max-width: 570px) {
    .image-default-product-list {
      width: 90px;
    }
  }
  </style>
