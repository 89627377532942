
const TRIM_CHARS = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
const TRUNC_REG = RegExp('(?=[' + TRIM_CHARS + '])');

export function reverseString (str: string) {
  return str.split('').reverse().join('');
}

export function truncateOnWord (str: string, limit?: number, fromLeft?: number) {
  if (fromLeft) {
    return reverseString(truncateOnWord(reverseString(str), limit));
  }
  const words = str.split(TRUNC_REG);
  let count = 0;
  return words.filter((word) => {
    count += word.length;
    return count <= limit;
  }).join('');
}
