import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import globalConfig from 'config';
dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = globalConfig.timezone;

const reservationSetTimeToModifier = (date) => {
  // default null
  let modifiedDate = '';
  if (date !== '') {
    // set the time to 23:59
    modifiedDate = dayjs.tz(date, TIMEZONE).hour(23).minute(59).format();
  }
  return modifiedDate
};

const reservationSetTimeFromModifier = (date) => {
  let modifiedDate = '';
  if (date !== '') {
    modifiedDate = dayjs.tz(date, TIMEZONE).format();
  }
  return modifiedDate;
}

const reservationGetTimeFromModifier = (date) => {
  let modifiedDate = '';
  if (date !== '') {
    modifiedDate = dayjs.tz(date, TIMEZONE).format();
  }
  return modifiedDate;
};

const reservationGetTimeToModifier = (date) => {
  let modifiedDate = '';
  if (date !== '') {
    modifiedDate = dayjs.tz(date, TIMEZONE).format();
  }
  return modifiedDate;
}

export { reservationSetTimeToModifier, reservationGetTimeFromModifier, reservationSetTimeFromModifier, reservationGetTimeToModifier };
