<template>
  <div class="badge-list">
    <slot v-for="(index, name) in $slots" v-slot:[name] :name="name" />
  </div>
</template>
<script>

export default {
  methods: {}
};
</script>
<style>
.badge-list {
  pointer-events: none;
  position: absolute;
  display: grid;
  justify-content: center;
  align-items:start;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}</style>
