import { productThumbnailPath } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { reservationGetTimeToModifier, reservationSetTimeToModifier, reservationGetTimeFromModifier, reservationSetTimeFromModifier } from 'theme/components/theme/anyrent/helpers/reservationTimeModifier'

import config from 'config'

export const ProductTile = {
  name: 'ProductTile',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      clicks: 0,
      placeholder: '/assets/placeholder.jpg'
    }
  },
  computed: {
    productLink () {
      const originalLink = formatProductLink(this.product, currentStoreView().storeCode)
      const params = this.dateFrom && this.dateTo ? `&dateFrom=${this.dateFrom}&dateTo=${this.dateTo}` : null
      const newLink = `${originalLink}${params || ''}`
      return newLink
    },
    thumbnail () {
      // todo: play with the image based on category page filters - eg. when 'red' color is chosen, the image is going to be 'red'
      const thumbnail = productThumbnailPath(this.product)
      return this.getThumbnail(thumbnail, config.products.thumbnails.width, config.products.thumbnails.height)
    },
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.placeholder,
        error: this.placeholder
      }
    },
    isOnSale () {
      return [true, '1'].includes(this.product.sale) ? 'sale' : ''
    },
    isNew () {
      return [true, '1'].includes(this.product.new) ? 'new' : ''
    },
    dateFrom: {
      get () {
        return this.$store.state.reservations.dateFrom;
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateFrom', date)
      }
    },
    dateTo: {
      get () {
        return this.$store.state.reservations.dateTo || '';
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateTo', date)
      }
    }
  },
  methods: {
    modifySetToDate (date) {
      return reservationSetTimeToModifier(date);
    },
    modifyGetToDate (date) {
      return reservationGetTimeToModifier(date);
    },
    modifyGetFromDate (date) {
      return reservationGetTimeFromModifier(date);
    },
    modifySetFromDate (date) {
      return reservationSetTimeFromModifier(date);
    }
  }
}
