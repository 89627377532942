<template>
  <div class="product align-center w-100 pb5 m2"
       v-observe-visibility="visibilityChanged"
       @mouseover="showFullName = true" @mouseout="showFullName = false"
  >
    <div class="product__icons">
      <!-- <AddToWishlist :product="product">
        <div
          class="product__icon"
          :class="{'product__icon--active': isOnWishlist }"
          :title="isOnWishlist ? $t('Remove') : $t('Add to favorite') "
        >
          <i class="material-icons">{{ favoriteIcon }}</i>
        </div>
      </AddToWishlist>
      <AddToCompare :product="product">
        <div
          class="product__icon"
          :class="{'product__icon--active':isOnCompare } "
          :title="isOnCompare ? $t('Remove from compare') : $t('Add to compare')"
        >
          <i class="material-icons">compare</i>
        </div>
      </AddToCompare> -->
    </div>
    <router-link
      class="block no-underline product-link bg-cl-primary"
      :to="productLink"
      data-testid="productLink"
    >
      <div
        class="product-cover"
        :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
      >
        <div v-if="hasProblem">
          <i class="material-icons absolute redc" v-for="error in product.errMessage" :key="error.id">warning</i>
        </div>
        <!-- bg-cl-primary -->
        <product-image
          class="product-cover__thumb "
          :image="thumbnailObj"
          :alt="product.name | htmlDecode"
          :calc-ratio="false"
          data-testid="productImage"
        />

        <!--        TODO nahradit hodnotami z product badges-->
        <!--        <template v-for="badge in [{code:'set-price'}] ">-->
        <template v-for="badge in product.badges">
          <badge-list :key="badge.code">
            <product-badge
              image-style="image-default-product-list"
              :alignment="badge.alignment" :file-url="badge.file_url"
            />
          </badge-list>
        </template>
      </div>
      <div class="name-format">
        <p class="mb0 mt10 bg-cl-primary name" :class="showFullName ? 'cl-anyrent' : 'cl-accent'"
           v-if="!onlyImage"
        >
          {{ shorten(product.name) | htmlDecode }}
        </p>
        <!-- </transition> -->
      </div>
      <div class="wrapper">
        <p class="text-left pl-2 ml-2">
          {{ shortenDescription(product.description) }}
        </p>
      </div>
      <span
        class="price-original mr5 lh30 cl-secondary"
        v-if="product.special_price && parseFloat(product.original_price_incl_tax) > 0 && !onlyImage"
      >od {{ product.original_price_incl_tax | price(storeView) }} {{ priceDetails }}</span>

      <span
        class="price-special lh30 cl-accent weight-700"
        v-if="product.special_price && parseFloat(product.special_price) > 0 && !onlyImage"
      >od {{ product.price_incl_tax | price(storeView) }} {{ priceDetails }}</span>

      <div
        class="cl-accent price-format"
        v-if="!product.special_price && parseFloat(product.price_incl_tax) > 0 && !onlyImage"
      >
        <span class="smt">od</span>
        <span class="smt main">{{ product.price_incl_tax | price(storeView) }}</span>
        <!-- <span class="smt hidden-xs">{{ priceDetails }}</span> -->
        <span class="smt hidden-xs">{{ priceDay }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
import ProductImage from './ProductImage'
import { IsOnWishlist } from '@vue-storefront/core/modules/wishlist/components/IsOnWishlist'
import { IsOnCompare } from '@vue-storefront/core/modules/compare/components/IsOnCompare'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapGetters } from 'vuex'
import BadgeList from './BadgeList';
import ProductBadge from './ProductBadge';
import { truncateOnWord } from '@vue-storefront/core/helpers/string';

export default {
  mixins: [ProductTile, IsOnWishlist, IsOnCompare],
  components: {
    BadgeList,
    ProductImage,
    ProductBadge
  },
  props: {
    labelsActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    },
    hasProblem: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      priceDetails: '/ den vč. DPH',
      priceDay: '/ den',
      showFullName: false
    }
  },
  computed: {
    ...mapGetters({
      originalProduct: 'product/getOriginalProduct',
      getOriginalProduct: 'product/getOriginalProduct'
    }),
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.thumbnail
      }
    },
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border'
    },
    storeView () {
      return currentStoreView()
    }
  },
  methods: {
    shorten (text) {
      const length = 35;
      if (text.length <= length) return text;
      let tcText = text.slice(0, length);
      const last = tcText.length - 1;
      // while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

      // Fix for case when text dont have any `space`
      // last = last || length - clamp.length;

      tcText = text.slice(0, last);

      // return text.length + tcText + clamp;
      // return tcText + clamp;
      return text;
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
        }
      }
    },
    shortenDescription (description) {
      return truncateOnWord(description, 100) + '...';
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);
.redc {
  color:red
}
p.name{
  // color:red;
  line-height: 19px;
  font-size: 18px;
  height: 40px;
  overflow: hidden;
}

.name-format {
  min-height: 45px;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  font-family: 'TTCommons-Medium';
  @media (max-width: 767px) {
    // font-size: 16px;
  }
}
.price-format {
  padding-bottom:20px;
  padding-left:10px;
  padding-top:20px;
  padding-right: 10px;
  text-align: right;
  font-family: 'TTCommons-Bold';
  font-size: 18px;
  display: block;
}
.price-format .smt{
  font-size: 12px;
  font-family: 'TTCommons-Light';
  display: inline-block;
}
.price-format .smt.main{
  font-size: 18px;
  font-family: 'TTCommons-Bold';
}
.product {
  position: relative;
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
  &__icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-top: 10px;
  }
  &__icon {
    padding-top: 10px;
    opacity: 0;
    z-index: 2;
    transition: 0.3s opacity $motion-main;
    @media (max-width: 767px) {
      opacity: 1;
    }
    &--active {
      opacity: 1;
    }
  }
  &:hover {
    .product__icon {
      opacity: 1;
    }
  }
}

.price-original {
  text-decoration: line-through;
}

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}

.product-cover {
  overflow: hidden;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__thumb {
    // Leaving this original magic math here as a code comment for now
    // padding-bottom: calc(143.88% / (164.5 / 100));
    // @media screen and (min-width: 768px) {
    //   padding-bottom: calc(300% / (276.5 / 100));
    // }
    flex: 1;
    opacity: 0.8;
    will-change: opacity, transform;
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;

    ::v-deep img {
      overflow: auto;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &:hover {
      .product-cover__thumb {
        opacity: 1;
        transform: scale(1.1);
      }
      &.sale::after,
      &.new::after {
        opacity: 0.8;
      }
    }
  }

  &.sale {
    &::after {
      @extend %label;
      content: 'Sale';
    }
  }
  &.new {
    &::after {
      @extend %label;
      content: 'New';
    }
  }
}

.text-left {
  text-align: left
}

.pl-2 {
  padding-left: size(2);
}

.ml-2 {
  margin-left: 0.5rem;
}

.wrapper {
  height: 80px;
}
.product-link {
  height: 100%;
}
</style>
